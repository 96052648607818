import React, { useCallback, useEffect, useRef, useState } from "react";
import style from "./styles.module.scss";
import { formatDate, formatTime } from "../../utils/dateTime";
import { BsGeo } from "react-icons/bs";
import { BiCheck, BiSubdirectoryRight, BiTime, BiX } from "react-icons/bi";
import ApprovalConst from "../../services/api/constants/Approval";
import { Box } from "@mui/material";
import { If } from "../If";
import { ModalItemApproval } from "../ModalItemApproval";
import { useApproval } from "../../providers/Approval";
import { IfLoaderInsideScreen } from "../IfLoaderInsideScreen";

interface CardApprovalProps {
  list: [];
  updateList: () => Promise<void>;
}

interface ApprovalToUpdate {
  id: number;
  status: "approved" | "refused";
}

export const CardItemApproval = () => {
  const [open, setOpen] = React.useState(false);
  const [approval, setApproval] = React.useState<ApprovalToUpdate>({
    id: 0,
    status: "approved",
  });

  const { getApprovals } = useApproval();

  const [loading, setLoading] = useState(false);
  const [listApprovals, setListApprovals] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const observer = useRef<IntersectionObserver | null>(null);

  const lastCardRef = useCallback(
    (node: HTMLDivElement) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const getApproval = useCallback(async () => {
    if (!hasMore) return;
    setLoading(true);
    const response = await getApprovals(page);

    setListApprovals((prev: any[]) => {
      const uniqueItems = response?.data?.filter(
        (item: any) => !prev?.some((prevItem: any) => prevItem?.id === item?.id)
      );
      return [...prev, ...uniqueItems];
    });
    setHasMore(response?.current_page < response?.last_page);
    setLoading(false);
  }, [getApprovals, page, hasMore]);

  useEffect(() => {
    getApproval();
  }, [getApproval, page]);

  const updateList = useCallback(async () => {
    setPage(1);
    setListApprovals([]);
    setHasMore(true);
    const response = await getApprovals(1);
    setListApprovals(response.data);
    setHasMore(response.current_page < response.last_page);
  }, [getApprovals]);

  const getApprovalTypeText = (typeId: number) => {
    switch (typeId) {
      case ApprovalConst.NEW_REGISTER:
        return "Solicitação de novo registro de ponto";
      case ApprovalConst.EDIT_REGISTER:
        return "Solicitação de alteração de registro de ponto";
      case ApprovalConst.DELETE_REGISTER:
        return "Solicitação de exclusão de registro de ponto";
      default:
        return "Solicitação de ajuste de registro de ponto";
    }
  };

  const getApprovalStatusClassName = (statusId: number) => {
    switch (statusId) {
      case ApprovalConst.STATUS_APPROVED:
        return style.approved;
      case ApprovalConst.STATUS_REJECTED:
        return style.rejected;
      default:
        return style.pending;
    }
  };

  const handleOpen = (item: ApprovalToUpdate) => {
    setOpen(true);
    setApproval(item);
  };

  const handleClose = () => {
    setOpen(false);
    setApproval({ id: 0, status: "approved" });
  };

  return (
    <>
      <IfLoaderInsideScreen loading={loading && page === 1}>
        {listApprovals.length > 0 &&
          listApprovals.map((item: any, index: number) => {
            return (
              <div
                className={style.card}
                key={item.uuid}
                ref={index === listApprovals.length - 1 ? lastCardRef : null}
              >
                <div className={style.containerTitle}>
                  <div className={style.details}>
                    <span className={style.title}>Status: {""}</span>
                    <span
                      className={getApprovalStatusClassName(
                        item.point_adjustment_status_id
                      )}
                    >
                      {item.point_adjustment_status.name}
                    </span>
                  </div>
                  <span>Data: {formatDate(item.schedule)}</span>
                </div>
                <div className={style.containerContent}>
                  <div className={style.content}>
                    <div className={style.titleKindOfApproval}>
                      <p>
                        {getApprovalTypeText(item.point_adjustment_type_id)}
                      </p>
                    </div>
                    <div className={style.address}>
                      <span>
                        <BsGeo
                          style={{
                            marginRight: ".5rem",
                            marginBottom: ".5rem",
                          }}
                        />
                      </span>
                      <p className={style.pointTitle}>
                        {" "}
                        Horário para aprovação:{" "}
                      </p>
                    </div>
                    <p>
                      {" "}
                      <span className={style.description}>
                        <BiTime style={{ marginRight: ".5rem" }} />{" "}
                        {formatTime(item.schedule)}
                      </span>
                    </p>
                    <div className={style.address}>
                      <span>
                        <BiSubdirectoryRight
                          style={{
                            marginRight: ".5rem",
                            marginBottom: ".5rem",
                          }}
                        />
                      </span>
                      <p className={style.pointTitle}> Tipo de registro: </p>
                    </div>
                    <p>
                      {" "}
                      <span className={style.description}>
                        <BiTime style={{ marginRight: ".5rem" }} /> {item.type}
                      </span>
                    </p>
                    <If
                      condition={
                        item.point_adjustment_status_id ===
                        ApprovalConst.STATUS_PENDING
                      }
                    >
                      <Box className={style.buttons}>
                        <button
                          onClick={() =>
                            handleOpen({ id: item.id, status: "approved" })
                          }
                        >
                          Aprovar <BiCheck style={{ fontSize: "1.1rem" }} />
                        </button>
                        <button
                          onClick={() =>
                            handleOpen({ id: item.id, status: "refused" })
                          }
                        >
                          Recusar <BiX style={{ fontSize: "1.1rem" }} />
                        </button>
                      </Box>
                    </If>
                  </div>
                </div>
              </div>
            );
          })}
      </IfLoaderInsideScreen>
      {loading && page > 1 && <div>Carregando mais...</div>}
      {!hasMore && <div>Não há mais itens para carregar.</div>}
      <If condition={approval.id !== 0}>
        <ModalItemApproval
          open={open}
          handleClose={handleClose}
          updateList={updateList}
          approval={approval}
        />
      </If>
    </>
  );
};
