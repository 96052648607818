import { createContext, useCallback, useContext, useState } from "react";
import { customToast } from "../../utils/toast";
import { useAuth } from "../Auth/Auth";
import { user } from "../Auth/services";

const HistoryContext = createContext<any>(null);

export const HistoryProvider = ({ children }: any) => {
  const [shift, setShift] = useState([]);
  const [loader, setLoading] = useState(true);
  const { userData } = useAuth();
  const [history, setHistory] = useState<any[]>([]);

  const getShift = (user_id: any, set: any) => {
    setLoading(true);
    user
      .getShifts(user_id)
      .then((res: any) => {
        set(res.data.response);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getHistory = useCallback(
    async (setLoader: any, page: number = 1) => {
      setLoader(true);
      if (userData == null) {
        return console.log("User Data nulo");
      }
      return await user
        .history(userData, page)
        .then((response) => {
          setHistory((prevHistory: any) => {
            const newItems = response.data.response?.data || [];
            const uniqueItems = prevHistory.concat(
              newItems.filter(
                (item: any) =>
                  !prevHistory.some(
                    (existingItem: any) => existingItem.id === item.id
                  )
              )
            );
            return uniqueItems;
          });
          setLoader(false);
          return response?.data?.response;
        })
        .catch((err) => {
          setLoader(false);
          customToast("Erro ao carregar histórico", "error");
        });
    },
    [userData]
  );

  return (
    <HistoryContext.Provider
      value={{
        shift,
        setShift,
        getShift,
        loader,
        history,
        getHistory,
        setHistory,
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
};

export const useHistory = () => useContext(HistoryContext);
