import { createContext, useContext } from "react";
import { customToast } from "../../utils/toast";
import { servicesApproval } from "./services";

const ApprovalContext = createContext<any>(null);

export const ApprovalProvider = ({ children }: any) => {
  const getApprovals = async (page: number = 1) => {
    try {
      const response = await servicesApproval.getApprovals(page);

      return response.data.response;
    } catch (err) {
      customToast("Erro ao carregar os ajustes", "error");
    }
  };

  const updateStatusApproval = async (idApproval: number, status: string) => {
    try {
      await servicesApproval.updateStatusApproval(idApproval, status);
      customToast("Status da aprovação atualizado com sucesso", "success");
    } catch (err) {
      customToast("Erro ao atualizar o status do ajuste", "error");
    }
  };

  return (
    <ApprovalContext.Provider value={{ getApprovals, updateStatusApproval }}>
      {children}
    </ApprovalContext.Provider>
  );
};

export const useApproval = () => useContext(ApprovalContext);
