import { createContext, useContext } from "react";
import { customToast } from "../../utils/toast";
import { servicesRequest } from "./services";

const RequestContext = createContext<any>(null);

export const RequestProvider = ({ children }: any) => {
  const getSolicitedRequest = async (setListRequest: any, page: number = 1) => {
    try {
      const response = await servicesRequest.getRequest(page);
      setListRequest((prevList: any) => {
        const newItems = response.data.response?.data || [];
        const uniqueItems = prevList.concat(
          newItems.filter(
            (item: any) =>
              !prevList.some((existingItem: any) => existingItem.id === item.id)
          )
        );
        return uniqueItems;
      });

      return response.data.response;
    } catch (err) {
      customToast("Erro ao carregar solicitações", "error");
    }
  };

  return (
    <RequestContext.Provider value={{ getSolicitedRequest }}>
      {children}
    </RequestContext.Provider>
  );
};

export const useRequest = () => useContext(RequestContext);
