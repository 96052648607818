import React, { useEffect, useState, useCallback, useRef } from "react";
import { AiOutlineHistory } from "react-icons/ai";
import { Banner } from "../../components/Banner";
import { CardHistory } from "../../components/CardHistory";
import { IfLoaderInsideScreen } from "../../components/IfLoaderInsideScreen";
import { Layout } from "../../components/Layout";
import { useAuth } from "../../providers/Auth/Auth";
import { useHistory } from "../../providers/History";
import style from "./styles.module.scss";

export default function History() {
  const { getHistory, history } = useHistory();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { userData } = useAuth();
  const observer = useRef<IntersectionObserver | null>(null);

  const lastCardRef = useCallback(
    (node: HTMLDivElement) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    const fetchHistory = async () => {
      setLoading(true);
      try {
        const response = await getHistory(setLoading, page);
        if (response && response.data) {
          setHasMore(response.current_page < response.last_page);
        }
      } catch (error) {
        console.error("Error fetching history:", error);
      }
      setLoading(false);
    };
    fetchHistory();
  }, [getHistory, userData, page]);

  return (
    <Layout>
      <IfLoaderInsideScreen
        loading={loading && page === 1}
      ></IfLoaderInsideScreen>
      <Banner
        icon={<AiOutlineHistory className="history" size={25} />}
        text="Histórico"
      />
      <div className={style.container}>
        {history.map((item: any, index: number) => (
          <div
            key={`${item.id}-${index}`}
            ref={index === history.length - 1 ? lastCardRef : null}
          >
            <CardHistory list={item} />
          </div>
        ))}
      </div>
      {loading && page > 1 && (
        <div className={style.loadingMore}>Carregando mais...</div>
      )}
      {!hasMore && (
        <div className={style.loadingMore}>
          Não há mais itens para carregar.
        </div>
      )}
    </Layout>
  );
}
