import api from "../../services/api/api";

export const servicesApproval = {
  getApprovals: async (page: number = 1) => {
    return await api.get(`/pointAdjustments?page=${page}`);
  },

  updateStatusApproval: async (id: number, status: string) => {
    return await api.put(`/pointAdjustments/${id}/response`, {
      response_status: status,
    });
  },
};
