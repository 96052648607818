import { BsClockHistory } from "react-icons/bs";
import { Banner } from "../../components/Banner";
import { CardRequest } from "../../components/CardRequest";
import { Layout } from "../../components/Layout";
import style from "./styles.module.scss";
export default function Solicitation() {
  return (
    <Layout>
      <Banner text="Solicitações" icon={<BsClockHistory />} />
      <div className={style.container}>
        <CardRequest />
      </div>
    </Layout>
  );
}
