import React, { useCallback, useEffect, useRef, useState } from "react";
import style from "./styles.module.scss";
import { formatDateTime, formatTime } from "../../utils/dateTime";
import { BsGeo } from "react-icons/bs";
import { BiTime } from "react-icons/bi";
import { ModalDetails } from "../Modal";
import { If } from "../If";
import { switchCaseIcons } from "../../utils/switchCaseIcons";
import { useRequest } from "../../providers/Request";
import { IfLoaderInsideScreen } from "../IfLoaderInsideScreen";

export const CardRequest = () => {
  const { getSolicitedRequest } = useRequest();

  const [loading, setLoading] = useState(false);
  const [listRequest, setListRequest] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const observer = useRef<IntersectionObserver | null>(null);

  const lastCardRef = useCallback(
    (node: HTMLDivElement) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const getSolicitation = useCallback(async () => {
    if (!hasMore) return;
    setLoading(true);
    const response = await getSolicitedRequest(setListRequest, page);
    setHasMore(response?.current_page < response?.last_page);
    setLoading(false);
  }, [getSolicitedRequest, page, hasMore]);

  useEffect(() => {
    getSolicitation();
  }, [getSolicitation, page]);

  const [open, setOpen] = React.useState(false);
  const [item] = React.useState(null);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IfLoaderInsideScreen loading={loading && page === 1}>
        {listRequest.length > 0 &&
          listRequest.map((item: any, index: number) => {
            return (
              <div
                className={style.card}
                key={index}
                ref={index === listRequest.length - 1 ? lastCardRef : null}
              >
                <div className={style.containerTitle}>
                  <div className={style.details}>
                    <span>{item.status}</span>
                    <span>{switchCaseIcons(item.status)}</span>
                  </div>
                  <span>Data: {formatDateTime(item.old_schedule)}</span>
                </div>
                <div className={style.containerContent}>
                  <div className={style.content}>
                    <div className={style.address}>
                      <span>
                        <BsGeo
                          style={{
                            marginRight: ".5rem",
                            marginBottom: ".5rem",
                          }}
                        />
                      </span>
                      <p className={style.pointTitle}> Ponto Atual: </p>
                    </div>
                    <p>
                      {" "}
                      <span className={style.description}>
                        <BiTime style={{ marginRight: ".5rem" }} />{" "}
                        {item.point_register != null &&
                          item.point_register.type}{" "}
                        {"  "}
                        {formatTime(item.old_schedule)}
                      </span>
                    </p>
                    <div className={style.address}>
                      <BsGeo style={{ marginRight: ".5rem" }} />
                      <p className={style.pointTitle}> Ponto Alterado: </p>
                    </div>
                    <p className={style.description}>
                      {" "}
                      <BiTime style={{ marginRight: ".5rem" }} />{" "}
                      {item.schedule !== null
                        ? `${item.point_register.type} ${formatTime(
                            item.point_register.schedule
                          )}`
                        : "Aguardando Análise"}
                    </p>
                  </div>
                  <div className={style.obs}>
                    <p>{item.obs === null ? "" : `Observação: ${item.obs}`}</p>
                  </div>
                </div>
              </div>
            );
          })}
      </IfLoaderInsideScreen>
      <If condition={item != null}>
        <ModalDetails
          open={open}
          item={item}
          handleClose={handleClose}
          setOpen={setOpen}
        />
      </If>
      {loading && page > 1 && <div>Carregando mais...</div>}
      {!hasMore && <div>Não há mais itens para carregar.</div>}
    </>
  );
};
